import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"

const Navbar = () => {
  const [menu, setMenu] = useState(false)

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setMenu(prevState => !prevState)
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Intellivets" style={{ height: "50px" }} />
          </Link>
          {/* Hamburger menu */}
          <div
            role="button"
            tabIndex={0}
            className={`navbar-burger burger ${menu ? "is-active" : ""}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
            onKeyDown={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${menu ? "is-active" : ""}`}>
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" to="/#specialty-services">
              SPECIALTY SERVICES
            </Link>
            <Link className="navbar-item" to="/#our-company">
              OUR COMPANY
            </Link>
            <Link className="navbar-item" to="/#contact-us">
              CONTACT US
            </Link>
            <a className="navbar-item" href="https://app.intellivets.com/login">
              LOGIN
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
