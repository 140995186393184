import React from "react"
import { Formik, Field, Form } from "formik"
import awsExports from "../../aws-exports"
import Amplify from "aws-amplify"
import { API } from "aws-amplify"
import * as Yup from "yup"
import EmailIcon from "../../images/email-footer.svg"

Amplify.configure(awsExports)

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  message: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
})

export const ContactUsForm = () => {
  const [successMsg, setSuccessMsg] = React.useState(false)

  return (
    <div className="contact-us-form-column">
      <h4 id="contact-us">CONTACT US</h4>
      <p>We would love to hear from you! Please email us with any inquiries.</p>
      <p>
        <img src={EmailIcon} alt="email" />{" "}
        <a href="mailto:hello@intellivets.com">hello@intellivets.com</a>
      </p>
      {successMsg ? (
        <h3 className="success-msg">Your Message has been sent!</h3>
      ) : (
        <Formik
          validationSchema={SignupSchema}
          initialValues={{
            email: "",
            name: "",
            message: "",
          }}
          onSubmit={async values => {
            const data = {
              body: values,
            }
            try {
              await API.post("contactformapi", "/contact", data)
              setSuccessMsg(true)
            } catch (err) {
              console.error(err)
            }
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field
                id="email"
                name="email"
                placeholder="From: your email (required)"
                type="email"
                className={`${
                  errors.email && touched.email ? "has-error" : ""
                }`}
              />
              <Field
                id="name"
                name="name"
                placeholder="Your Name (required)"
                className={`${errors.name && touched.name ? "has-error" : ""}`}
              />

              <Field
                id="message"
                name="message"
                component="textarea"
                placeholder="type your message here..."
                rows="4"
                className={`${
                  errors.message && touched.message ? "has-error" : ""
                }`}
              />

              <button
                className={`button ${isSubmitting ? "is-loading" : ""}`}
                type="submit"
                disabled={isSubmitting}
              >
                SEND
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
