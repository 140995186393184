import React from "react"
import { Link } from "gatsby"
import { ContactUsForm } from "./contactUsForm"
import InstagramIcon from "../../images/instagram-footer.svg"
import TwitterIcon from "../../images/twitter-footer.svg"
import FacebookIcon from "../../images/facebook-footer.svg"
import LogoImg from "../../images/footer-logo.png"

const ContactUs = () => {
  return (
    <section className="section contact-us-section">
      <div className="container is-fullhd">
        <div className="contact-us-columns">
          <div className="contact-us-links-column">
            <div className="contact-us-logo-wrapper">
              <img src={LogoImg} alt="logo" />
            </div>
            <div className="contact-us-menu">
              <ul>
                <li>
                  <a href="#specialty-services">SPECIALTY SERVICES</a>
                </li>
                <li>
                  <a href="#our-company">OUR COMPANY</a>
                </li>
              </ul>
            </div>
          </div>
          <ContactUsForm />
        </div>
        <div className="copyright-footer">
          <div className="copyright-footer-columns">
            <ul className="copyright-footer-links">
              <li className="is-hidden-mobile">© INTELLIVETS</li>
              {/* <li>
                <Link to="/">Terms of Use</Link>
              </li> */}
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
            <div className="social-icons">
              <a href="https://www.facebook.com/intellivets/">
                <img src={FacebookIcon} alt="FacebookIcon" />
              </a>
              <a href="https://twitter.com/intellivets">
                <img src={TwitterIcon} alt="TwitterIcon" />
              </a>
              <a href="https://www.instagram.com/intellivets/">
                <img src={InstagramIcon} alt="InstagramIcon" />
              </a>
            </div>
          </div>
          <p className="copy-right-mobile is-hidden-tablet">© INTELLIVETS</p>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
