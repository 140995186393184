import * as React from "react"
import "../scss/main.scss"
import Navbar from "./navbar"
import Footer from "./footer/contactUs"
const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
